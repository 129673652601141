<template>
  <div id="brands">
    <v-row>
      <v-col v-for="brand in brands" :key="brand.name" cols="12" md="3">
        <div>
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="600"
              :elevation="hover ? '4' : '2'"
            >
              <a :href="brand.url" target="_blank">
                <v-img :aspect-ratio="721 / 367" :src="brand.img"> </v-img>
                <v-card-text class="black--text">
                  {{ brand.name }}
                </v-card-text>
              </a>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BrandTiles",
  data() {
    return {
      brands: [
        {
          name: "wearebs3.co.uk",
          img: require("@/assets/discover/thumb_wearebs3.jpg"),
          url: "https://wearebs3.co.uk",
        },
        {
          name: "wearenailsea.co.uk",
          img: require("@/assets/discover/thumb_wearenailsea.jpg"),
          url: "https://wearenailsea.co.uk",
        },
        {
          name: "made.innorthsomerset.co.uk",
          img: require("@/assets/discover/thumb_madeinnorthsomerset.jpg"),
          url: "https://made.innorthsomerset.co.uk",
        },
        {
          name: "bristolmenu.co.uk",
          img: require("@/assets/discover/thumb_bristolmenu.jpg"),
          url: "https://bristolmenu.com",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#brands {
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.75;
    position: absolute;
    width: 100%;
  }
}
</style>
